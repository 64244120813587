import React, {useEffect, useState} from 'react';
import './App.css';
import AnimationSVG from "./animation/AnimationSVG";
import font from "fonts/Proxima Nova Fonts Collection.jpg"

import background1 from "../src/assets/Reply-Background-2-1030x579.png"
import background2 from "../src/assets/3-10-1030x579.png"
import background3 from "../src/assets/40-1030x579.png"
import background4 from "../src/assets/50-1030x579.png"
import logo from "../src/assets/Technology-Reply-LOGO_Negative-RGB_Transparent-1.png"

function App() {
    const listOfBackgrounds = [background1,
        background2,
        background3,
        background4
    ]
    const getRandomInt = (): number => {
        return Math.floor(Math.random() * 4);
    };

    const [background, setBackground] = useState("")
    useEffect(() => {

        setBackground(listOfBackgrounds[getRandomInt()])
    }, []);

    return (
        <div id={"appContainer"} style={{backgroundImage:`url(${background})`, backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '100vh',}} className="App">
            <div style={{height: '100vh',width:"100%",position:"absolute",top:0,opacity:0.6,background:"#0a0a0a"}}/>

            <section className="section section-body">
                <div className="logo-wrapper image">
                    <a href="" style={{textDecoration: "none"}}>
                        <img className={"graphic-logo"}
                            src={logo}
                             alt="logo"/>
                    </a>
                </div>
                <h2 className="cmp-title animated ">
                    <br/>
                    The selected web page is not available
                    <br/>
                    from your current geographic location.
                    <br/>
                    We apologize for any inconvenience this may cause.
                    <br/>
                </h2>
            </section>
            {/*<div className={"main"}>*/}
            {/*    <div className={"svg"}><AnimationSVG></AnimationSVG></div>*/}
            {/*    <br/>*/}
            {/*    <div className={"text"} >*/}
            {/*        <br/>*/}
            {/*        The selected web page is not available*/}
            {/*        <br/>*/}
            {/*        from your current geographic location.*/}
            {/*        <br/>*/}
            {/*        We apologize for any inconvenience this may cause.*/}
            {/*        <br/>*/}

            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default App;
